<template>
    <div :class="[$style.success, isRegister || $style.addTop]">
        <pt-icon icon="icon-code-success" :iconStyle="{ width: '172px', height: '172px' }"></pt-icon>
        <p>{{ $t('setup.client_success') }}</p>
        <pt-button :class="$style.btn" size="medium" @click="handleGoSetEvents">
            {{ $t('setup.continue') }}
        </pt-button>
        <pt-button :class="$style.btn" type="outline" size="medium" @click="handleGoUsers">
            {{ $t('setup.start') }}
        </pt-button>
    </div>
</template>

<script>
export default {
    name: 'SetupSuccess',

    data() {
        const { register } = this.$root.query || {};
        return {
            isRegister: Boolean(register)
        };
    },

    methods: {
        handleGoSetEvents() {
            this.$router.push({ name: 'SetEvents', query: this.$route.query });
        },
        handleGoUsers() {
            this.$router.push({ name: 'Users' });
        }
    }
};
</script>

<style lang="scss" module>
@import '@/styles/import.scss';

.success {
    padding-top: 60px;

    &.addTop {
        padding-top: 160px;
    }

    svg {
        display: block;
        margin: 0 auto;
    }

    p {
        max-width: 470px;
        margin: 40px auto 50px;
        color: $pt-black-600;
        font-size: 24px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 33px;
        text-align: center;
    }

    .btn {
        width: 360px;
        display: flex;
        margin: 0 auto 28px;
    }
}
</style>
